import ContactBox from "../../components/boxContact"
import Footer from "../../components/footer"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import React from "react"
import imgSanierung01 from "../../assets/leistungen/sanierung01.jpg"
import imgSanierung02 from "../../assets/about/bild-folgt.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Sanierung und Neueindeckungen"
      Content="Von allen Gebäudeteilen ist der Witterung das Dach immer am stärksten ausgesetzt. Das hinterlässt Spuren, nicht nur am Dach selbst, sondern auch an der Dacheindeckung, der Dämmung, dem Dachstuhl sowie an der Fassade, bzw. dem Mauerwerk.
        <br />
        Eine rechtzeitige Dachsanierung kann sich auszahlen. Zum einen hat eine Sanierung Auswirkungen auf die Einsparung von Energie. Zum anderen hat eine Dachsanierung einen positiven Effekt auf Holz sowie Fenster des Daches.
        <br />
        Wir als Dachdecker im Meisterbetrieb raten unseren Kunden daher, das Dach zum einen regelmäßig auf eventuelle Schäden vom Fachmann überprüfen zu lassen und rechtzeitig zu sanieren. Zum anderen informieren wir über alle Möglichkeiten einer Sanierung."
    />
    <LeistungBox
      Color="gray"
      Image={imgSanierung01}
      IsImageLeft={true}
      Title="Ihr Beitrag für eine sichere Zukunft"
      Content="Zu den geeigneten Maßnahmen zur Energieeinsparung gehören Vorhaben im Bereich des Dachgeschossbaus wie Dachfenstereinbau, Gaubenbau, Innenausbau, Aufsattelung und Umdeckung, wobei die vielfältigsten Materialien zum Einsatz kommen können.
        <br />
        Im Bereich der Außenwände bieten Sanierungsarbeiten, der Einbau neuer Fenster oder die Bekleidung der Außenwände beste Energiesparmöglichkeiten.
        <br />
        Die Bekleidung mit wärmegedämmten, hinterlüfteten Systemen ist eine ideale Möglichkeit, bestehende Gebäude nach den Erfordernissen der EnEV und nach den individuellen Gestaltungswünschen des Bauherrn zu modernisieren. Schiefer, Keramik, Faserzement, Metallelemente und vieles mehr stehen hier gestalterisch zur Verfügung und werden vom Dachdecker fachgerecht angebracht."
    />
    <LeistungBox
      Color="white"
      Image={imgSanierung02}
      //ImageSource="Tim Reckmann/ccnull.de"
      IsImageLeft={false}
      Title="Energie einsparen - Wohnqualität erhöhen"
      Content="Die Energieeinsparverordnung schafft die Grundlage für eine einheitliche Erfassung der im Gebäude auftretenden Begleitfaktoren der Energieverwendung.
      <br />
      Das bedeutet für Sie als Bauherrn, dass Sie mit den Innungsbetrieben des Dachdeckerhandwerks kompetente Berater finden, die fachübergreifend eine umfassende Analyse des Energiehaushaltes Ihres Hauses selbst erstellen oder anfertigen lassen. So werden die notwendigen und sinnvollen Maßnahmen zur Behebung der Schwachstellen bis ins Detail benannt. Der Mitgliedsbetrieb der Dachdecker-Innung führt in Kooperation mit seinen Partnern aus anderen Gewerken alle gewünschten Maßnahmen nach Ihren Vorstellungen kompetent aus."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
